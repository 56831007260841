import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import PostTags from "../components/PostTags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";

const PostTemplate = ({ data, pageContext }) => {
  const [showComment, setShowComment] = useState(false);
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }
  return (
    <Layout>
      <div>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div>
          {post.cover !== "none" && (
            <img style={{ width: "100%" }} src={post.cover} alt="cover" />
          )}
          {post.title !== "none" && (
            <h1 style={{ marginTop: 20 }}>{post.title}</h1>
          )}
          <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
          <div className="post-meta">
            <PostTags tags={post.tags} />
            {post.showSharing && (
              <SocialLinks postPath={slug} postNode={postNode} />
            )}
          </div>
          <UserInfo config={config} />
          {!showComment && (
            <div
              className="comment-button"
              role="presentation"
              onClick={() => setShowComment(true)}
            >
              <span style={{ paddingRight: 10 }}>Comment</span>
              <div className="arrow-down" />
            </div>
          )}
          {showComment && <Disqus postNode={postNode} />}
          <Footer config={config} />
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
